import React from 'react';
import { Grid, Typography } from '@material-ui/core';
import Img from 'gatsby-image/withIEPolyfill';
import Particles from 'react-particles-js';
import { CustomTitle } from '../CustomText';
import { useStyles } from './styles';
import clsx from 'clsx';

Banner.defaultProps = {
  interactive: true,
};


export default function Banner({title, subtitle, img, header, children, interactive}) {
  const classes = useStyles();

  return (
    <div className={classes.bannerContainer}>
      <Img fluid={img} className={header ? classes.locationImg : classes.locationImgFooter} />
      <div className={interactive ? classes.particles : classes.particlesFooter}>
        <Particles
          height={typeof window !== `undefined` ? window.outerHeight : '100%'}
          params={{
            'particles': {
              'number': {
                'value': 180,
                'density': {
                  'enable': true,
                  'value_area': 2051.7838682439087
                }
              },
              'color': {
                'value': '#ffffff'
              },
              'shape': {
                'type': 'circle',
                'stroke': {
                  'width': 0,
                  'color': '#000000'
                },
                'polygon': {
                  'nb_sides': 5
                },
                'image': {
                  'src': 'img/github.svg',
                  'width': 100,
                  'height': 100
                }
              },
              'opacity': {
                'value': 0.5,
                'random': false,
                'anim': {
                  'enable': false,
                  'speed': 1,
                  'opacity_min': 0.1,
                  'sync': false
                }
              },
              'size': {
                'value': 3,
                'random': true,
                'anim': {
                  'enable': false,
                  'speed': 40,
                  'size_min': 0.1,
                  'sync': false
                }
              },
              'line_linked': {
                'enable': true,
                'distance': 150,
                'color': '#ffffff',
                'opacity': 0.4,
                'width': 1
              },
              'move': {
                'enable': true,
                'speed': 2,
                'direction': 'none',
                'random': false,
                'straight': false,
                'out_mode': 'out',
                'bounce': false,
                'attract': {
                  'enable': false,
                  'rotateX': 600,
                  'rotateY': 1200
                }
              }
            },
            'interactivity': {
              'detect_on': 'canvas',
              'events': {
                'onhover': {
                  'enable': true,
                  'mode': 'repulse'
                },
                'onclick': {
                  'enable': true,
                  'mode': 'push'
                },
                'resize': true
              },
              'modes': {
                'grab': {
                  'distance': 400,
                  'line_linked': {
                    'opacity': 1
                  }
                },
                'bubble': {
                  'distance': 400,
                  'size': 40,
                  'duration': 2,
                  'opacity': 8,
                  'speed': 3
                },
                'repulse': {
                  'distance': 200,
                  'duration': 0.4
                },
                'push': {
                  'particles_nb': 4
                },
                'remove': {
                  'particles_nb': 2
                }
              }
            },
            'retina_detect': true
          }}
        />
      </div>
      <Grid item className={clsx(header ? classes.textCenter : classes.textCenterFooter)}>
        {title && <Typography color={'secondary'} variant={'h4'} align={'center'} gutterBottom
                              data-sal={'slide-down'}
                              data-sal-delay="300"
                              data-sal-easing="ease">
          {title}
        </Typography>}
        {subtitle && <CustomTitle title={subtitle} color={'secondary'} customClasses={classes.bannerSubtitle} />}
        {children}
      </Grid>
    </div>
  );
}
