import { makeStyles } from '@material-ui/core';
import { keyframes } from '../../assets/styles/keyframes';


export const useStyles = makeStyles(theme => ({
  bannerContainer: {
    height: '100%',
    width: '100%',
    position: 'relative',
    textAlign: 'center',
    backgroundColor: 'transparent'
  },
  subtitleContainer: {
    position: 'relative',
    textAlign: 'center',
  },
  subtitle: {
    margin: 'auto',
    zIndex: 5,
    width: 'fit-content',
  },
  textCenter: {
    position: 'absolute',
    top: '45%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '100%',
    padding: '0 1rem',
    WebkiTransition: 'all 1s ease-in-out',
    MozTransition: 'all 1s ease-in-out',
    OTransition: 'all 1s ease-in-out',
    transition: 'all 1s ease-in-out',
  },
  textCenterFooter: {
    position: 'absolute',
    top: '16%',
    left: '0%',
    width: '100%',
    [theme.breakpoints.down('sm')]: {
      top: '20%',
    },
    [theme.breakpoints.down('xs')]: {
      top: '11%',
    },
  },
  locationImg: {
    minHeight: '30rem',
  },
  locationImgFooter: {
    minHeight: '55rem',
  },
  bannerSubtitle: {
    textTransform: 'capitalize !important',
    fontSize: '22px !important',
    fontWeight: 600,
    fontFamily: 'Montserrat, sans-serif !important',
    [theme.breakpoints.down('xs')]: {
      fontSize: '21px !important',
    },
  },
  particles: {
    position: 'absolute',
    top: 0,
    width: '100%',
    height: '100%',
    overflow: 'hidden',
    zIndex: 1,
  },
  particlesFooter: {
    position: 'absolute',
    top: 0,
    width: '100%',
    height: '100%',
    overflow: 'hidden',
  },
  ...keyframes,
}));
